



















































































































































































import { defineComponent, computed, ref } from "@vue/composition-api";
import { Dog, DogReport } from "@/store";
import moment from "moment";
import {
  useGenerateReport,
  useGetDogStatus,
  useGetDogTrainingTime,
} from "@/composables/helpers";
import { DOG_STATUS_DISPLAY_TEXT } from "@/store/constants";

interface Props {
  loading: boolean;
  dogs: Dog[];
}

export default defineComponent({
  name: "DogReportComponent",
  props: {
    loading: Boolean,
    dogs: Array,
  },
  setup(props: Props) {
    const years = computed<number[]>(() => [
      ...new Set(
        props.dogs.map((dog) =>
          new Date(dog.createDate as string).getFullYear()
        )
      ),
    ]);
    const year = ref<number | null>(null);

    function getDate(date: string) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    function getDogReportData() {
      let reportDogs: Dog[] | DogReport[] = props.dogs.filter(
        (dog) => dog.deleted === null
      );

      function getPlacementDate(dog: Dog) {
        if (dog.adoptionDate) {
          return getDate(dog.adoptionDate.toString());
        }

        if (dog.placementDate) {
          return getDate(dog.placementDate.toString());
        }

        return "Not Applicable";
      }

      reportDogs = reportDogs.map((dog: Dog) => {
        const totalPublicMinutes = dog.dogTrainings
          ? useGetDogTrainingTime(dog.dogTrainings).totalPublicMinutes
          : 0;
        const totalRegularMiutes = dog.dogTrainings
          ? useGetDogTrainingTime(dog.dogTrainings).totalRegularMinutes
          : 0;

        return {
          name: dog.name,
          birthDate: getDate(dog.birthDate.toString()),
          acquisitionDate: dog.acquiredDate
            ? getDate(dog.acquiredDate.toString())
            : "Not Applicable",
          status: useGetDogStatus(dog),
          totalPublicMinutes:
            totalPublicMinutes > 0 ? totalPublicMinutes / 60 : 0,
          totalRegularMinutes:
            totalRegularMiutes > 0 ? totalRegularMiutes / 60 : 0,
          placementDate: getPlacementDate(dog),
          applicant: dog.applicant
            ? `${dog.applicant.firstName} ${dog.applicant.lastName}`
            : "Not Applicable",
          recertificationDate: dog.trainingCertExpires
            ? getDate(dog.trainingCertExpires.toString())
            : "Not Applicable",
          createDate: getDate((dog.createDate || "").toString()),
        };
      }) as DogReport[];

      if (year.value) {
        return reportDogs.filter(
          (dog) => new Date(dog.createDate).getFullYear() === year.value
        ) as DogReport[];
      }
      return reportDogs as DogReport[];
    }

    const reportDogs = computed<DogReport[] | null>(() => getDogReportData());

    function getTotalPlacedByAdoption() {
      return (reportDogs.value || []).filter(
        (dog) => dog.status === DOG_STATUS_DISPLAY_TEXT.PLACED_ADOPTION
      ).length;
    }

    function getTotalPlacedWithRecipient() {
      return (reportDogs.value || []).filter(
        (dog) => dog.status === DOG_STATUS_DISPLAY_TEXT.PLACED
      ).length;
    }

    function getTotalPlaced() {
      return getTotalPlacedByAdoption() + getTotalPlacedWithRecipient();
    }

    function getTotalPassed() {
      return (reportDogs.value || []).filter(
        (dog) => dog.status === DOG_STATUS_DISPLAY_TEXT.PASSED
      ).length;
    }

    function getTotalFailed() {
      return (reportDogs.value || []).filter(
        (dog) => dog.status === DOG_STATUS_DISPLAY_TEXT.FAILED
      ).length;
    }

    function getTotalCompleteTraining() {
      return getTotalPassed() + getTotalFailed();
    }

    function downloadDogReport() {
      const report = useGenerateReport("dog", reportDogs.value as DogReport[]);
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(report as string);
      anchor.target = "_blank";
      anchor.download = `Dogs_${getDate(
        new Date().toISOString().substr(0, 10)
      )}.csv`;
      anchor.click();
    }

    function clearYear() {
      year.value = null;
    }

    return {
      reportDogs,
      DOG_STATUS_DISPLAY_TEXT,
      getTotalPlaced,
      getTotalPlacedByAdoption,
      getTotalPlacedWithRecipient,
      getTotalPassed,
      getTotalFailed,
      getTotalCompleteTraining,
      downloadDogReport,
      years,
      year,
      clearYear,
    };
  },
});
