
























import { defineComponent, computed, onBeforeMount } from "@vue/composition-api";
import {
  useStore,
  useDogService,
  useApplicantService,
} from "@/composables/providers";
import { Applicant, Dog } from "@/store";
import { DogGetters } from "@/store/dog";
import { DogActions } from "@/store/dog/index";
import { ApplicantActions, ApplicantGetters } from "@/store/applicant";
import ApplicantReportComponent from "./ApplicantReport.vue";
import DogReportComponent from "./DogReport.vue";

export default defineComponent({
  name: "Reports",
  components: {
    ApplicantReportComponent,
    DogReportComponent,
  },
  setup() {
    const store = useStore();
    const dogService = useDogService();
    const applicantService = useApplicantService();
    const dogs = computed<Dog[]>(() => store.getters[DogGetters.DOGS]);
    const applicants = computed<Applicant[]>(
      () => store.getters[ApplicantGetters.APPLICANTS]
    );
    const dogsLoading = computed<boolean>(
      () => store.getters[DogGetters.LOADING]
    );
    const applicantsLoading = computed<boolean>(
      () => store.getters[ApplicantGetters.LOADING]
    );
    const loading = computed<boolean>(
      () => applicantsLoading.value || dogsLoading.value
    );

    onBeforeMount(async () => {
      Promise.all([
        await store.dispatch(DogActions.LIST, { service: dogService }),
        await store.dispatch(ApplicantActions.LIST, {
          service: applicantService,
        }),
      ]);
    });

    return {
      dogs,
      loading,
      applicants,
    };
  },
});
