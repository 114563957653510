






























































































































































import { defineComponent, computed, ref } from "@vue/composition-api";
import { Applicant, Service, ApplicantReport } from "@/store";
import moment from "moment";
import {
  APPLICANT_STATUS_LIST,
  STATE_LIST,
  APPLICANT_STATUS_DISPLAY_TEXT,
} from "@/store/constants";
import { useGenerateReport } from "@/composables/helpers";

interface Props {
  loading: boolean;
  applicants: Applicant[];
}

export default defineComponent({
  name: "ApplicantReportComponent",
  props: {
    loading: Boolean,
    applicants: Array,
  },
  setup(props: Props) {
    const years = computed<number[]>(() => [
      ...new Set(
        props.applicants.map((applicant) =>
          new Date(applicant.createDate).getFullYear()
        )
      ),
    ]);
    const year = ref<number | null>(null);

    function getDate(date: string) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    function transformApplicantStatus(applicantStatus: number) {
      const status = APPLICANT_STATUS_LIST.find(
        ({ value }) => value === applicantStatus
      );
      return (status || {}).text;
    }

    function getState(state: string) {
      return STATE_LIST.filter((item) => item.value === state)
        .map((state) => state.text)
        .toString();
    }

    function getApplicantReportData() {
      let reportApplicants:
        | Applicant[]
        | ApplicantReport[] = props.applicants.filter(
        (applicant) => applicant.deleted === null
      );
      reportApplicants = reportApplicants.map((applicant: Applicant) => {
        return {
          firstName: applicant.firstName,
          lastName: applicant.lastName,
          birthDate: getDate(applicant.birthDate.toString()),
          state: getState(applicant.stateOrTerritory),
          status: applicant.status
            ? transformApplicantStatus(applicant.status)
            : "Not Applicable",
          startDate: applicant.startDate
            ? getDate(applicant.startDate.toString())
            : "Not Applicable",
          homeVisitDate: applicant.homeVisitDate
            ? getDate(applicant.homeVisitDate.toString())
            : "Not Applicable",

          military: (applicant.services as Service[])
            .filter((service) => service.type === "military")
            .map((service) => service.name)
            .join(", "),
          firstResponder: (applicant.services as Service[])
            .filter((service) => service.type === "responder")
            .map((service) => service.name)
            .join(", "),

          dog: applicant.dog ? applicant.dog.name : "Not Applicable",
          created: getDate(applicant.createDate.toString()),
        };
      }) as ApplicantReport[];
      if (year.value) {
        return reportApplicants.filter(
          (applicant) =>
            new Date(applicant.created).getFullYear() === year.value
        ) as ApplicantReport[];
      }
      return reportApplicants as ApplicantReport[];
    }

    const reportApplicants = computed<ApplicantReport[] | null>(() =>
      getApplicantReportData()
    );

    function getMilitaryTotal() {
      if (reportApplicants.value) {
        return (reportApplicants.value || []).filter(
          (applicant) => applicant.military && !applicant.firstResponder
        ).length;
      }
    }

    function getResponderTotal() {
      if (reportApplicants.value) {
        return (reportApplicants.value || []).filter(
          (applicant) => !applicant.military && applicant.firstResponder
        ).length;
      }
    }

    function getResponderMilitaryTotal() {
      if (reportApplicants.value) {
        return (reportApplicants.value || []).filter(
          (applicant) => applicant.military && applicant.firstResponder
        ).length;
      }
    }

    function getUnderReview() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) =>
            applicant.status === APPLICANT_STATUS_DISPLAY_TEXT.UNDER_REVIEW
        ).length;
      }
    }

    function getApplicantInterview() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) =>
            applicant.status ===
            APPLICANT_STATUS_DISPLAY_TEXT.APPLICANT_INTERVIEW
        ).length;
      }
    }

    function getSponsorInterview() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) =>
            applicant.status === APPLICANT_STATUS_DISPLAY_TEXT.SPONSOR_INTERVIEW
        ).length;
      }
    }

    function getPhysicianInterview() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) =>
            applicant.status ===
            APPLICANT_STATUS_DISPLAY_TEXT.PHYSICIAN_INTERVIEW
        ).length;
      }
    }

    function getInvestigation() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) =>
            applicant.status ===
            APPLICANT_STATUS_DISPLAY_TEXT.BACKGROUND_INVESTIGATION
        ).length;
      }
    }

    function getInspection() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) =>
            applicant.status === APPLICANT_STATUS_DISPLAY_TEXT.HOME_INSPECTION
        ).length;
      }
    }

    function getApproval() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) =>
            applicant.status === APPLICANT_STATUS_DISPLAY_TEXT.APPROVAL
        ).length;
      }
    }

    function getRejections() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) =>
            applicant.status === APPLICANT_STATUS_DISPLAY_TEXT.REJECTION
        ).length;
      }
    }

    function getNoStatus() {
      if (reportApplicants.value) {
        return reportApplicants.value.filter(
          (applicant) => applicant.status === "Not Applicable"
        ).length;
      }
    }

    function clearYear() {
      year.value = null;
    }

    function downloadApplicantReport() {
      const report = useGenerateReport(
        "applicant",
        reportApplicants.value as ApplicantReport[]
      );
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(report as string);
      anchor.target = "_blank";
      anchor.download = `Applicants_${getDate(
        new Date().toISOString().substr(0, 10)
      )}.csv`;
      anchor.click();
    }

    return {
      reportApplicants,
      getMilitaryTotal,
      getResponderTotal,
      getResponderMilitaryTotal,
      getUnderReview,
      getApplicantInterview,
      getSponsorInterview,
      getPhysicianInterview,
      getInvestigation,
      getInspection,
      getApproval,
      getRejections,
      getNoStatus,
      downloadApplicantReport,
      years,
      year,
      clearYear,
    };
  },
});
